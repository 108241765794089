import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Card, CardHeader, CardBody, CardFooter, UncontrolledAlert } from 'reactstrap'
import { ApiService } from 'services'
import Header from '../../components/Headers/Header'
import Pagination from '../../components/Pagination'
import Filters from './Filters'
import Table from './Table'

function List () {

  const [isLoading, setIsLoading] = useState(false)

  const [data, setData] = useState([])

  // Opciones de filtro
  const [currentOptions, setCurrentOptions] = useState({
    año: '',
    numeroAfiliado: ''
  })

  // Opciones de orden del listado
  const [orderOptions, setOrderOptions] = useState({
    field: 'id',
    dir: -1
  })

  const [validData, setValidData] = useState(true)
  const [dataError, setDataError] = useState('')

  const fetchData = async (newOptions) => {
    try {
      setIsLoading(true)

      let hasError = false
      setValidData(true)
      setDataError('')

      if (currentOptions.año.length === 0) {
        setDataError('El campo Año es requerido para hacer la consulta.')
        hasError = true
      }

      if (currentOptions.numeroAfiliado.length != 12) {
        setDataError('Número de afiliado inválido. El número de afiliado no debe incluir barra y debe contener 12 caracteres.')
        hasError = true
      }

      if (!hasError) {
        // Hacemos la petición
        const res = await ApiService.getAll('authorizationsioma', currentOptions)

        // Verificamos que no haya errores
        if (res.status !== 'ok') {
          setData([])
          alert('Ha ocurrido un error al obtener el listado de Autorizaciones, intente nuevamente mas tarde.')
          setIsLoading(false)
          return
        }

        setData(res.payload)
      } else {
        setValidData(false)
      }

      // Terminamos la carga
      setIsLoading(false)
    } catch (err) {
      setData([])
      console.log(err)
    }
  }

  const changeOrder = (field, dir) => {
    const sortedData = [...data].sort((a, b) => {
      if (typeof a[field] === 'string') {
        // Ordenar por cadenas alfabéticamente
        if (dir == -1) {
          return a[field].localeCompare(b[field]);
        } else {
          return b[field].localeCompare(a[field]);
        }
      }

      if (dir == -1) {
        return a[field] - b[field];
      } else {
        return b[field] - a[field];
      }
    });
    setOrderOptions({ field, dir })
    setData(sortedData);
  }

  const changeFilter = (key, value) => {
    if (key == 'año') {
      if (value.length == 4) {
        if (value < 0) {
          value = '0';
        } else if (value > new Date().getFullYear()) {
          value = new Date().getFullYear();
        }
      } else if (value.length > 4) {
        return;
      }
    }

    setCurrentOptions({
      ...currentOptions,
      [key]: value
    })
  }

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        {
          !validData &&
          <UncontrolledAlert color="danger">
            <strong>Datos Erroneos: </strong> {dataError}
          </UncontrolledAlert>
        }
        <Row>
          <Col>
            <Filters fetchData={ fetchData } changeFilter={ changeFilter } currentOptions={ currentOptions } />

            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col md='8'>
                    <h3 className="mb-0">Lista de Autorizaciones IOMA</h3>
                  </Col>
                  
                </Row>
              </CardHeader>
              <CardBody>
                <Table id="requests-table" items={ data } isLoading={ isLoading } changeOrder={ changeOrder } currentOrder={ orderOptions } />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default List
