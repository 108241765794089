import React, { useEffect, useContext } from "react"
import { Route } from "react-router-dom"
import { Container } from "reactstrap"
import AdminNavbar from "components/Navbars/AdminNavbar.js"
import AdminFooter from "components/Footers/AdminFooter.js"
import Sidebar from "components/Sidebar/Sidebar.js"
import { UserContext } from 'context'
import { routes } from "router"

function Admin (props) {
  useEffect(() => {
    document.documentElement.scrollTop = 0
    document.scrollingElement.scrollTop = 0
    return () => { return }
  })

  const { getCurrentUser } = useContext(UserContext)
  const user = getCurrentUser()

  const linkLogo = (user.insurer_id !== 10) ? '/admin/presentations' : '/admin/authorizations-ioma';
  
  const getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name
      }
    }
    return "Brand"
  }

  return (
    <>
      <Sidebar
        {...props}
        routes={routes}
        logo={{
          innerLink: linkLogo,
          imgSrc: require('assets/img/brand/logo.png'),
          imgAlt: '...',
        }}
      />
      <div className="main-content">
        <AdminNavbar {...props} brandText={getBrandText(props.location.pathname)} />
        {
          routes.map((prop, key) => {
            if (prop.layout === '/admin') {
              if ('roles' in prop && !prop.roles.some(r => user.roles.includes(r))) {
                return null
              }

              return <Route key={ key } exact path={ prop.layout + prop.path } component={ prop.component } />
            }

            return null
          })
        }
        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
    </>
  )
}

export default Admin
