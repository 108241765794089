import React, { useEffect, useState, useContext } from 'react'
import { ApiService } from 'services'

import { Col, FormGroup, Input, Row, Button } from 'reactstrap'

import CollapsibleCard from '../../components/CollapsibleCard'

function RequestFilters(props) {
  const { fetchData, changeFilter, currentOptions } = props;

  const currentYear = new Date().getFullYear();

  return (
    <CollapsibleCard title="Filtros">
      <Row>
        <Col sm={4}>
          <FormGroup>
            <label>Número de Afiliado</label>
            <Input 
              type="text"
              value={currentOptions.numeroAfiliado}
              onChange={ (event) => changeFilter('numeroAfiliado', event.target.value) }
            />
          </FormGroup>
        </Col>
        <Col sm={4}>
          <FormGroup>
            <label>Año</label>
            <Input 
              type="number" 
              min="1990"
              max={currentYear}
              placeholder="----"
              value={currentOptions.año}
              onChange={ (event) => changeFilter('año', event.target.value) } />
          </FormGroup>
        </Col>
        <Col sm={4}>
          <FormGroup>
            <Button className="btn-round btn-md mt-4" color="primary" onClick={fetchData} >
              Buscar
            </Button>
          </FormGroup>
        </Col>
      </Row>
    </CollapsibleCard>
  )
}

export default RequestFilters
