import React from 'react'
import { Table } from "reactstrap"
import { Link } from "react-router-dom"
import TableLoader from 'components/TableLoader'

export default function (props) {
  const { items, isLoading, currentOrder, changeOrder, id } = props

  const getIconClassBySorted = (field) => {
    if (currentOrder.field === field) {
      if (currentOrder.dir === -1) {
        return 'fa fa-caret-down'
      } else {
        return 'fa fa-caret-up'
      }
    }

    return 'fas fa-sort'
  }

  const setNewOrder = (field) => {
    // Si el campo elegido ya está seleccionado, sólo cambiamos el orden
    if (currentOrder && currentOrder.field === field) {
      changeOrder(
        currentOrder.field,
        currentOrder.dir === 1 ? -1 : 1
      )
    // Si es un nuevo campo, lo indicamos como seleccionado y reseteamos el orden
    // a ascendente
    } else {
      changeOrder(field, 1)
    }
  }

  /**
   * Configuración de la cabecera de las columnas
   */
  const columns = [
    {
      name: 'nombreAfiliado',
      label: 'Apellido y Nombre',
      sort: true,
      value: row => row.nombreAfiliado ?? '-'
    },
    {
      name: 'matricula',
      label: 'Mat.',
      sort: true,
      value: row => row.matricula ?? '-'
    },
    {
      name: 'apellidoNombre',
      label: 'Kinesiólogo',
      sort: true,
      value: row => row.apellidoNombre ?? '-'
    },
    {
      name: 'numeroAutorizacion',
      label: 'Nro Autorización',
      sort: true,
      value: row => row.numeroAutorizacion ?? '-'
    },
    {
      name: 'moduloDiagnostico',
      label: 'Módulo',
      sort: true,
      value: row => row.moduloDiagnostico ?? '-'
    },
    {
      name: 'domicilio',
      label: 'Domicilio',
      sort: true,
      value: row => row.domicilio ?? '-'
    },
    {
      name: 'fechaAutorizacion',
      label: 'Fecha de Autorización',
      sort: true,
      value: row => row.fechaAutorizacion ?? '-'
    },
    {
      name: 'fechaOrdenMedica',
      label: 'Fecha de Órden Médica',
      sort: true,
      value: row => row.fechaOrdenMedica ?? '-'
    },
    {
      name: 'cantidadSesiones',
      label: 'Sesiones',
      sort: true,
      value: row => row.cantidadSesiones ?? '-'
    },
    {
      name: 'estado',
      label: 'Estado',
      sort: true,
      value: row => row.estado ?? '-'
    }
  ]

  return (
    <Table className="align-items-center table-flush" responsive id={ id }>
      <thead className="thead-light">
        <tr>
          {
            // Cabecera
            columns.map((column) => {
              if (column.show === false) {
                return null
              }

              if (!column.sort) {
                return <th key={column.name} scope='col' className={column.classes ?? ''}>{ column.label }</th>
              }

              return (
                <th key={column.name} scope='col' className={ `clickable ${column.classes ?? ''}` } onClick={ () => setNewOrder(column.name) }>
                  { column.label }
                  { <i className={ getIconClassBySorted(column.name) }></i> }
                </th>
              )
            })
          }
        </tr>
      </thead>
      <tbody>
        {
          // Datos
          !isLoading
          ? Array.isArray(items) && items.length
            ? items.map(row =>
              <tr key={row.id}>
                { columns.map( column => column.show === false ? null : <td key={ column.name } className={ column.classes ?? '' } >{ column.value(row) }</td> ) }
              </tr>
            )
            : <tr>
                <td colSpan={columns.length + 1} className='text-center'>
                  <span>No se encontraron presentaciones</span>
                </td>
              </tr>
          : <tr>
              <td colSpan={columns.length + 1} className='text-center'>
                <TableLoader />
              </td>
            </tr>
        }
      </tbody>
    </Table>
  )
}