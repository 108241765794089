import React, { useContext, useState } from "react"
import { NavLink as NavLinkRRD, Link } from "react-router-dom"
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col
} from "reactstrap";
import { UserContext } from 'context'
import config from 'config'

function Sidebar(props) {
  const [ collapseOpen, setCollapseOpen ] = useState(false)
  const { getCurrentUser } = useContext(UserContext)
  const user = getCurrentUser()
  const { routes, logo } = props

  const toggleCollapse = () => {
    setCollapseOpen(!collapseOpen)
  };


  const closeCollapse = () => {
    setCollapseOpen(false)
  };

  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank"
    };
  }
  const navLinks = () => {
    return routes.map((item, key) => {
      if (item.show === false) {
        return null
      }

      if ('roles' in item && !item.roles.some(r => user.roles.includes(r))) {
        return null
      }

      if (item.name === "Gestion de Presentaciones" && user.insurer_id === 10) {
        return null;
      }

      if (item.name === "Autorizaciones IOMA" && (!user.roles.some(r => [1, 3, 4].includes(r)) && user.insurer_id !== 10)) {
        return null;
      }

      return <NavItem key={ key }>
        <NavLink to={ item.layout + item.path } tag={NavLinkRRD} onClick={ () => closeCollapse() } activeClassName="active">
          <i className={item.icon} /> { item.name }
        </NavLink>
      </NavItem>
    })
  }

  return (
    <Navbar className="navbar-vertical fixed-left navbar-light bg-white" expand="md" id="sidenav-main">
      <Container fluid>
        <button className="navbar-toggler" type="button" onClick={ () => toggleCollapse() }>
          <span className="navbar-toggler-icon" />
        </button>

        {
          logo
          ? <NavbarBrand className="pt-0 pb-0" {...navbarBrandProps}>
              <img alt={logo.imgAlt} className="navbar-brand-img" src={logo.imgSrc} width='190px' />
            </NavbarBrand>
          : null
        }

        <div className="small text-right text-light font-weight-bold">v{ config.version }{ config.env !== 'prod' ? ` - ${ config.env }` : '' }</div>

        <Nav className="align-items-center d-md-none">
          <UncontrolledDropdown nav>
            <DropdownToggle nav>
              <Media className="align-items-center">
                <span>LG</span>
              </Media>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
              <DropdownItem className="noti-title" header tag="div">
                <h6 className="text-overflow m-0">Bienvenido!</h6>
              </DropdownItem>
              <DropdownItem to="/admin/user-profile" tag={Link}>
                <i className="ni ni-single-02" />
                <span>Cambiar contraseña</span>
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem href="#pablo" onClick={e => e.preventDefault()}>
                <i className="ni ni-user-run" />
                <span>Logout</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>

        <Collapse navbar isOpen={ collapseOpen }>
          <div className="navbar-collapse-header d-md-none">
            <Row>
              {
                logo
                  ? <Col className="collapse-brand" xs="6">
                      <Link to={ logo.innerLink ? logo.innerLink : logo.outterLink }><img alt={ logo.imgAlt } src={ logo.imgSrc } /></Link>
                    </Col>
                  : null
                }
              <Col className="collapse-close" xs="6">
                <button className="navbar-toggler" type="button" onClick={() => toggleCollapse()} >
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
          </div>
          <Nav navbar>
            { navLinks() }
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default Sidebar
